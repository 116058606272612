<template>
  <el-dialog
    :title="`定时${type == 1 ? '开启' : '关闭'}`"
    :visible.sync="dialogVisible"
    width="30%"
  >
    <el-form
      ref="form"
      :model="formData"
      label-width="80px"
    >
      <el-form-item label="开启日期">
        <el-date-picker
          v-model="formData.dateVal"
          type="datetime"
          placeholder="选择日期"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm:ss"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button
        type="primary"
        @click="submit"
        :loading="loading"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { settingAdScheduleOpenTask } from '@/api/adManagement.js';
// import { settingAdScheduleOpenTaskMobile } from '@/api/multiPlatform.js';
export default {
  props: {
    adScheduleOpenTaskType: {
      // 开启时间枚举
      type: Array,
      default: () => {
        return [];
      },
    },
    selectedRowScopes: {
      type: Array,
      default: () => [],
    },
    setTimeIds: {
      // 开启的 id集合
      type: Array,
      default: () => {
        return [];
      },
    },
    level: {
      type: String,
      default: 'campaign',
    },
    platform: String,
    timeType: {
      type: String,
      default: '',
    },
    type: {
      type: Number | String,
      default: 1,
    },
  },
  data() {
    return {
      dialogVisible: false,
      formData: {
        dateVal: '',
        // taskType: '',
      },
      // 设置不能选则当天之前的时间
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
        start: '00:00',
        step: '00:30',
        end: '23:30',
      },
      loading: false,
    };
  },
  computed: {
    ...mapState('user', ['userInfo']),
  },
  methods: {
    show() {
      this.dialogVisible = true;
    },
    hide() {
      this.dialogVisible = false;
    },
    submit() {
      if (!this.formData.dateVal) {
        return this.$message.warning('请选择开启日期');
      }
      let request = {
        adAccountId: this.$store.getters.accountId,
        level: this.level,
        handleTime: this.formData.dateVal,
        // taskType: this.formData.taskType,
        ids: this.setTimeIds,
        scheduleType: this.type,
      };
      this.loading = true;
      let arr = this.selectedRowScopes.map((v) => {
        return {
          id:
            this.level == 'campaign'
              ? v.campaign_id || v.ad.campaignId
              : this.level == 'adset'
              ? v.adGroupId || v.adset_id || v.ad.adGroupId
              : v.ad_id || (v.ad && v.ad.adId) || v.id,
          level: this.level,
          platform: this.platform ? this.platform : v.ad && v.ad.platform,
          adAccountId: v.rowAccountId ? v.rowAccountId : v.ad ? v.ad.adAccountId : this.$store.getters.accountId,
          thirdUserId: v.rowThirdNum
            ? v.rowThirdNum
            : v.ad
            ? v.ad.credentialId && v.ad.credentialId.toString()
            : this.$store.state.num.selectedNum,
          campaignId: v.campaign_id,
          adGroupId: v.adset_id,
          adGroupAdId: this.level == 'ad' ? v.id : null,
        };
      });
      let obj = { ...request, gimpUserId: this.userInfo.data.id, adInfos: [...arr] };
      delete obj.ids;
      delete obj.adAccountId;
      settingAdScheduleOpenTask(JSON.stringify(obj))
        .finally(() => {
          this.loading = false;
          this.hide();
        })
        .then((res) => {
          if (res.code === 0) {
            this.$message.success(`定时${this.type == 1 ? '开启' : '关闭'}已打开！`);
            if (this.timeType != 'mobile') {
              this.$emit('settingComplete', this.formData, this.type);
            } else {
              this.$emit('settingCompleteMobile', this.formData, this.type);
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input,
.el-select {
  width: 100%;
}
</style>
