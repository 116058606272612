<template>
  <div class="logWrap">
    <el-drawer
      title="操作日志"
      :visible="showLogVisible"
      @close="close"
      @opened="openInit"
      :modal="true"
      :modal-append-to-body="false"
      size="50%"
    >
      <div class="tableWrap">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="updateTime" label="操作时间" width="180">
          </el-table-column>
          <el-table-column prop="daily_budget" label="日预算(USD)" width="180">
          </el-table-column>
          <el-table-column prop="lifetime_budget" label="总预算(USD)">
          </el-table-column>
          <el-table-column prop="bid_amount" label="竞价价格(USD)">
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { queryBudgetList } from "@/api/adManagement";
export default {
  props: {
    showLogVisible: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableData: [],
    };
  },
  methods: {
    close() {
      this.$emit("update:showLogVisible", false);
    },
    openInit() {
      let id = this.row.id;
      queryBudgetList({ id: id },this.row.rowThirdNum).then((res) => {
        this.tableData = res.data.map(v=>{
          return {
            ...v,
            daily_budget:this.$store.getters.toBaseMoney(v.daily_budget),
            lifetime_budget:this.$store.getters.toBaseMoney(v.lifetime_budget),
          }
        });
      });
    },
  },
};
</script>
<style lang="scss">
.logWrap {
  .tableWrap {
    padding: 20px;
  }
  .my-table.el-table .el-table__body-wrapper {
    height: calc(100vh - 200px) !important;
  }
}
</style>