<template>
    <div>
        <el-dialog title="广告详情" 
            :visible="previewAds" 
            width="900px"
            :modal-append-to-body="false"
            :append-to-body="true"
            @close="handleClose">
            <ad-format :value="value"></ad-format>
        </el-dialog>
    </div>
</template>
<script>
import adFormat from './adFormat'
export default {
    props:['previewAds','value'],
    data() {
        return {
            
        }
    },
    components:{
        adFormat
    },
    methods: {
        handleClose(){
            this.$emit('close','previewAds')
        }
    },
}
</script>
<style scoped lang="scss">

</style>