<template>
    <div>
        <el-link type="primary" :href="`https://facebook.com/${row.page_id}`" target="_blank">{{row.page_name}}</el-link>
    </div>
</template>
<script>

export default {
    props: {
        row: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {

        }
    }
}
</script>