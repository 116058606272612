<template>
  <div>
    <el-dialog
      title="关联广告商品"
      :visible="relateSale"
      width="900px"
      @close="handleClose"
    >
      <sale-form
        @saleInfo="saleInfo"
        v-if="relateSale"
        ref="saleInfo"
      ></sale-form>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          @click="sure"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import saleForm from '../../createAd/components/addSale';
import { saleCampaignRel, bindManul } from '@/api/creatAd.js';
export default {
  props: ['relateSale', 'rowMsg', 'platform','accountId'],
  data() {
    return {
      info: null,
    };
  },
  components: {
    saleForm,
  },
  methods: {
    handleClose() {
      this.$emit('close', 'relateSale');
      this.info = null;
    },
    saleInfo(v) {
      this.info = { ...v };
    },
    sure() {
      if (!this.info || (!this.info.sale && this.platform == 'google')) {
        this.$message({
          type: 'warning',
          message: '请绑定商品',
        });
        return false;
      }
      if (!this.info || !this.info.site) {
        this.$message({
          type: 'warning',
          message: '请选择站点',
        });
        return false;
      }
      // console.log(this.info);
      if (this.platform === 'google') {
        let params = {
            platform: 'google',
            optimizer: this.$store.state.user.userInfo.data.id,
            adAccountId: this.rowMsg.row.account_id || this.accountId.toString(),//this.$store.getters.accountId,
            compaignId: this.rowMsg.row.id,
            compaignName: this.rowMsg.row.name,
            saleId: this.info.sale ? this.info.sale.saleId : null,
            collId: this.info.site.id,
        };
        bindManul(params).then((res) => {
          // console.log(res);
          if (res.code == 0) {
            this.$message({
              type: 'success',
              message: '关联成功',
            });
            this.handleClose();
            this.rowMsg.row.campaign_ref = 'REF';
            this.$emit('getList', this.rowMsg);
          }
        });
      } else {
        let params = {
          campaignId: this.rowMsg.row.id,
          campaignName: this.rowMsg.row.name,
          saleId: this.info.sale ? this.info.sale.saleId : null,
          collId: this.info.site.id,
          platform: this.platform || 'facebook',
          ad_account_id: this.$store.getters.accountId,
        };
        if(this.$refs.saleInfo.errorGoods){
          this.$message({
            type: 'warning',
            message:this.$refs.saleInfo.text
          });
          return;
        }
        saleCampaignRel(params, this.rowMsg.row.rowThirdNum).then((res) => {
          // console.log(res);
          this.$hideLoading()
          if (res.code == 0) {
            this.$message({
              type: 'success',
              message: '关联成功',
            });
            this.handleClose();
            this.rowMsg.row.campaign_ref = 'REF';
            this.$emit('getList', this.rowMsg);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss"></style>
