<template>
  <div>
    <localOrder
      :id="id"
      :level="level"
      :row="row.local_order"
      :disabled="!row.local_order"
    >
      <div
        v-if="row.local_order"
        :class="[!(row.local_order.validOrderCount && row.local_order.orderCount) ? 'lightColor' : '']"
      >
        <p>
          {{ row.local_order ? `${row.local_order.validOrderCount}/${row.local_order.orderCount}` : '--' }}
        </p>
        <p
          class="color9"
          style="height: 20px; line-height: 20px"
          v-if="row.spend && row.local_order && row.local_order.validOrderCount"
        >
          成本：{{ spend }}
        </p>
      </div>
      <p v-else>--</p>
    </localOrder>
  </div>
</template>
<script>
import { localOrder } from '@adCommonComponent';

export default {
  props: {
    row: {
      type: Object,
      default: () => {},
    },
    id: {
      type: [String, Number],
      default: '',
    },
    level: String,
  },
  components: { localOrder },
  data() {
    return {
      chartHide: true,
      chartShow: !this.chartHide,
    };
  },
  computed: {
    spend() {
      if (this.row.spend_usd) {
        let spend = this.row.spend_usd.slice(1);
        return (
          '$' +
          (spend / this.row.local_order.validOrderCount).toFixed(1) +
          '/$' +
          (spend / this.row.local_order.orderCount).toFixed(1)
        );
      } else {
        return (
          (this.row.spend / this.row.local_order.validOrderCount).toFixed(1) +
          '/' +
          (this.row.spend / this.row.local_order.orderCount).toFixed(1)
        );
      }
    },
  },
};
</script>
<style scoped lang="scss">
.lightColor {
  color: #bbb;
}
</style>
